import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import {useQuery} from '@apollo/client';
import {Q_COMPLAINT} from "../gql/complaint";
import { navigate } from 'gatsby';
import _ from 'lodash'
import {Box, Divider, HStack, Spacer, Text, VStack, Button,Image,Flex,Link, Collapse, useDisclosure} from "@chakra-ui/react";
import {ArrowDownIcon, ArrowUpIcon} from "@chakra-ui/icons";
import {format} from 'date-fns'
import ComplaintResponseFeedback from "./complaintResponseFeedback";
import {Trans} from "react-i18next";
const DetailComplaint = ({code}) => {

    const {data, loading, error} = useQuery(Q_COMPLAINT, {variables:{code}});
    const { isOpen, onToggle } = useDisclosure()

    if(loading) {
        return   <Box>
            Looking for the complaint ...
        </Box>
    }
    const complaint = data&&data.oneComplaint

    if(!complaint) {
        return   <Box>
                Not found
        </Box>

    }
    const responses = _.orderBy(complaint.responses,'date','desc')
    const colors = {waiting:"gray", onProgress:"tomato", handled:"green", responded:"green"}
    return (
        <VStack spacing={5}>

            <HStack>
                <Text fontSize="2xl">{complaint.category}</Text>
                <Spacer/>
                <Text >{complaint.channelId}</Text>
                <VStack spacing={-1.5}>
                    <Image src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${complaint.code}`}
                           alt={complaint.code}/>
                    <Text >  {complaint.code}</Text>


                </VStack>
            </HStack>
            <Text>{complaint.detailComplaint}</Text>
            <Box p={7} bg={colors[complaint.status]}  borderRadius="md" color="white">
                <Text><Trans>Your Complaint is </Trans> <Trans>{complaint.status}</Trans></Text>

            </Box>


            {responses.length && <VStack>
                <Text fontSize='2em' ><Trans>Responses</Trans></Text>


                <Box>
                    <Collapse startingHeight={20} in={isOpen} mt={4} animateOpacity>
                        {responses.map(r => <HStack>
                            <Box>
                                <Text fontWeight='bold' fontSize='1em' >
                                {format(new Date(r.date),'MMM dd')}
                                </Text>
                            </Box>
                            <Box>{r.response}</Box>
                        </HStack>)
                        }
                    </Collapse>
                </Box>



            </VStack>
            }

            {responses.length >1 &&<Button size="sm" variantColor="blue" onClick={onToggle}>
                {!isOpen ? <ArrowDownIcon/> : <ArrowUpIcon/>}
            </Button>}

            <HStack>
                <Button onClick={()=>navigate("/")}><Trans>Back</Trans></Button>
                <Spacer/>

                {responses.length&&<ComplaintResponseFeedback complaint={complaint}/>}

            </HStack>



        </VStack>
    );
};

export default DetailComplaint;
