import React from 'react';
import {Box, Text, useColorModeValue, Image, Flex, chakra, Button, HStack,Input} from "@chakra-ui/react";
import * as queryString from "query-string";
import DetailComplaint from "../components/detailComplaint";
import Layout from "../components/layout";
import {graphql} from "gatsby";
const ComplaintDetailPage = ({ location }) => {
    const { code} = queryString.parse(location.search);
    return (
        <Layout>
        <Box p={[2,5,20]} px={[0,10,80]} bg="#F5F7FD" minH="full"  >
            <Box bg="white" boxShadow="lg" p={5} borderRadius="lg">
                <DetailComplaint code={code}/>
            </Box>

        </Box>
        </Layout>
    );
};

export default ComplaintDetailPage;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    }
  
`;
