import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import {Button} from "@chakra-ui/button";
import {useDisclosure} from "@chakra-ui/hooks";
import {Radio, RadioGroup} from "@chakra-ui/radio";
import {Box, Stack} from "@chakra-ui/layout";
import {Textarea} from "@chakra-ui/textarea";
import {Tabs, Tab, TabList, TabPanel, TabPanels} from "@chakra-ui/tabs";
import { RatingComponent } from 'react-rating-emoji'
import "./UI/rating-style.css"
import { Text } from '@chakra-ui/react'
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import {useMutation} from "@apollo/client";
import {M_UPDATE_COMPLAINT} from "../gql/complaint";
import { useToast } from '@chakra-ui/react'
const ComplaintResponseFeedback = ({complaint}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [tabIndex, setTabIndex] = React.useState(0)
    const [value, setValue] = React.useState()
    const [message, setMessage] = React.useState()
    const [rating, setRating] = React.useState(0);
    const [updateComplaint] = useMutation(M_UPDATE_COMPLAINT);
    const toast = useToast()
    const {t} = useTranslation();
    const nextTab = async () => {
       if(tabIndex===1) {
           const feedBack= {
               responseFeedback: value,
               feedBackMessage: message,
               serviceRating: rating,
               feedBackDate: new Date(),
           }
           try {
               await updateComplaint({variables: {record: {_id: complaint.id, feedBack}}})
               toast({
                   title: 'Feedback Submitted',
                   description: "We've Received your feedback",
                   status: 'success',
                   duration: 6000,
                   isClosable: true,
               })
               setTabIndex(tabIndex + 1)
           } catch (e) {
               toast({
                   title: 'Error saving your feedback',
                   description: "We've trouble saving your feedback, please do this later",
                   status: 'error',
                   duration: 6000,
                   isClosable: true,
               })
           }

       }


        else if(tabIndex<2) {
            setTabIndex(tabIndex + 1)
        }
        else {
            onClose()
        }
    }
    return (
        <>
            <Button  size="md" variant='grad' onClick={onOpen}><Trans>Continue</Trans></Button>

            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    {/*<ModalHeader>Modal Title</ModalHeader>*/}
                    <ModalCloseButton />
                    <ModalBody>

                        <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
                            {tabIndex<2&&<TabList>
                                <Tab><Trans>Complaint Feedback</Trans></Tab>
                                <Tab><Trans>Service Rating</Trans></Tab>
                            </TabList>}

                            <TabPanels>
                                <TabPanel>
                                    <Stack spacing={4} direction='column'>
                                        <RadioGroup onChange={setValue} value={value}>
                                            <Stack spacing={4} direction='column'>
                                                <Radio size='lg'  colorScheme='green'  value='satisfied'><Trans>I am Satisfied with the response</Trans></Radio>
                                                <Radio size='lg'  colorScheme='red'  value='complaint'><Trans>I've a complaint regarding the response</Trans></Radio>
                                                <Radio size='lg'  colorScheme='blue'  value='info'><Trans>I need more information</Trans></Radio>
                                            </Stack>
                                        </RadioGroup>

                                        <Textarea value={message} onChange={e=>setMessage(e.target.value)} placeholder={t('Any thing you want to add')} />
                                    </Stack>
                                </TabPanel>
                                <TabPanel>
                                    <Stack>
                                        <Text fontSize='3xl'>
                                            <Trans>How did you find your experience so far?</Trans>
                                        </Text>
                                        <Box>
                                            <RatingComponent rating={rating} onClick={r=>setRating(r)} />
                                        </Box>

                                    </Stack>
                                </TabPanel>
                                <TabPanel>
                                    <Stack>
                                        <Text fontSize='3xl'><Trans>Thank you for your feedback </Trans> 🙏 </Text>
                                        <Text fontSize='md'><Trans>We will let you know when there is new update.</Trans></Text>
                                    </Stack>
                                </TabPanel>

                            </TabPanels>
                        </Tabs>



                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={nextTab}>{tabIndex<2?<Trans>Continue</Trans>:<Trans>Close</Trans>}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ComplaintResponseFeedback;
